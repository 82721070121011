import {BehaviorSubject} from "rxjs";
import API from "./api";
import config from "../config";

let dataLoader = {}

dataLoader.notifications = {
    data: new BehaviorSubject([]),
    fetchNotifications: () => {
        API.ensureReady().then(() => {
            API.getNotifications().then((notifications) => {
                dataLoader.notifications.data.next(notifications)
            }).catch((error) => {
                if (config.log) console.log('Failed to get notifications');
            });
        })
    },
    interval: undefined,
    register: () => {
        if (!dataLoader.notifications.interval) {
            dataLoader.notifications.fetchNotifications()
            dataLoader.notifications.interval = setInterval(() => dataLoader.notifications.fetchNotifications(), config.notificationsCheckInterval * 1000);
        }
    },
    unregister: () => {
        if (dataLoader.notifications.interval !== undefined) {
            clearInterval(dataLoader.notifications.interval)
            dataLoader.notifications.interval = undefined
            dataLoader.notifications.data.next([])
        }
    }
}

export default dataLoader;