import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import config from "../config";
import Swal from "sweetalert2";

const storeUserSession = (user, props) => {
    Cookies.set('user', JSON.stringify(user), { expires: 7 });

    props.i18n.changeLanguage(user.lang).then(_=>{});
    localStorage.setItem('lang', user.lang);
}

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();

    if (!user) {
        return false;
    }

    let decoded = {}
    try {
        decoded = jwtDecode(user.accessToken);
    } catch {
        return false
    }
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
        if (config.log) console.warn('Access token expired');
        return false;
    }

    return true;
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const user = Cookies.get('user');
    return user ? (typeof (user) === 'object' ? user : JSON.parse(user)) : null;
};

const isUserAdmin = () => {
    const user = getLoggedInUser()
    return user ? user.admin : false
}

const isUserPHT = () => {
    const user = getLoggedInUser()
    return user.account === null
}

const isUserDistributor = () => {
    const user = getLoggedInUser()
    return user.account !== null && user.accountData.typeName === "distributor"
}

const isUserCustomer = () => {
    const user = getLoggedInUser()
    return user.account !== null && user.accountData.typeName === "customer"
}

const getAccessToken = () => {
    const user = getLoggedInUser();

    if (!user) {
        return false;
    }

    return user.accessToken;
};

const checkPasswordStrength = (password, t) => {
    let passwordStrength = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')
    if(passwordStrength.test(password)) {
        return true;
    } else {
        Swal.fire({
            title: t('error'),
            text: t('password_strength'),
            icon: 'error',
            confirmButtonText: t('retry')
        });

        return false;
    }
}

const getTemperatureFormattedForUser = (temperature) => {
    const user = getLoggedInUser()
    return user !== null && user.unit === "far" ? ((temperature * 1.8) + 32) + " °F" : temperature + " °C"
}

export { storeUserSession, isUserAuthenticated, getLoggedInUser, isUserAdmin, isUserPHT, isUserDistributor, isUserCustomer, getAccessToken, checkPasswordStrength, getTemperatureFormattedForUser };
