import React from 'react';
import { Redirect } from "react-router-dom";
import { Route } from 'react-router-dom';

import { isUserAuthenticated } from './helpers/authUtils';
import dataLoader from "./helpers/dataLoader";

const MapPage = React.lazy(() => import('./pages/map/MapPage'));
const SystemsPage = React.lazy(() => import('./pages/systems/SystemsPage'));
const NotificationsPage = React.lazy(() => import('./pages/notifications/NotificationsPage'));
const SystemDetailPage = React.lazy(() => import('./pages/systems/SystemDetailPage'));
const SystemSetupPage = React.lazy(() => import('./pages/systems/SystemSetupPage'));
const SettingsPage = React.lazy(() => import('./pages/settings/SettingsPage'));
const FaqPage = React.lazy(() => import('./pages/support/FaqPage'));
const SystemsListAdminPage = React.lazy(() => import('./pages/systems/SystemsListAdminPage'));
const SystemCreateUpdatePage = React.lazy(() => import('./pages/systems/SystemCreateUpdatePage'));
const UsersAdminPage = React.lazy(() => import('./pages/users_admin/UsersAdminPage'));
const UserCreateUpdatePage = React.lazy(() => import('./pages/users_admin/UserCreateUpdatePage'));
const AccountsAdminPage = React.lazy(() => import('./pages/accounts_admin/AccountsAdminPage'));
const AccountCreateUpdatePage = React.lazy(() => import('./pages/accounts_admin/AccountCreateUpdatePage'));

// auth
const Login = React.lazy(() => import('./pages/auth/Login'));
const Logout = React.lazy(() => import('./pages/auth/Logout'));
const PasswordReset = React.lazy(() => import('./pages/auth/PasswordReset'));

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route {...rest} render={props => {

    const isAuthTokenValid = isUserAuthenticated();

    if (!isAuthTokenValid) {
      // not logged in so redirect to login page with the return url
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    } else {
      dataLoader.notifications.register()
    }

    // const loggedInUser = getLoggedInUser();

    // check if route is restricted by role
    /* if (roles && roles.indexOf(loggedInUser.role) === -1) {
      // role not authorised so redirect to home page
      return <Redirect to={{ pathname: '/' }} />
    } */

    // authorised so return component
    return <Component {...props} />
  }} />
)

const routes = [
  // auth and user
  { path: '/login', name: 'Login', component: Login, route: Route },
  { path: '/logout', name: 'Logout', component: Logout, route: Route },
  { path: '/forgot-password', name: 'Forgot password ?', component: PasswordReset, route: Route },

  { path: '/locate', name: 'Map', component: MapPage, route: PrivateRoute, roles: ['Admin'] },
  { path: '/systems', name: 'Systems', component: SystemsPage, route: PrivateRoute, roles: ['Admin'] },
  { path: '/system/:systemId', name: 'SystemDetail', component: SystemDetailPage, route: PrivateRoute, roles: ['Admin'] },
  { path: '/systemModify/:systemId', name: 'SystemModification', component: SystemCreateUpdatePage, route: PrivateRoute, roles: ['Admin'] },
  { path: '/systemSetup/:systemId', name: 'SystemSetup', component: SystemSetupPage, route: PrivateRoute, roles: ['Admin'] },
  { path: '/support', name: 'Support', component: FaqPage, route: PrivateRoute, roles: ['Admin'] },
  { path: '/settings', name: 'Settings', component: SettingsPage, route: PrivateRoute, roles: ['Admin'] },
  { path: '/notifications', name: 'Notifications', component: NotificationsPage, route: PrivateRoute, roles: ['Admin'] },
  { path: '/users_admin', name: 'UsersAdmin', component: UsersAdminPage, route: PrivateRoute, roles: ['Admin'] },
  { path: '/systems_admin', name: 'SystemsListAdmin', component: SystemsListAdminPage, route: PrivateRoute, roles: ['Admin'] },
  { path: '/system_create', name: 'SystemCreate', component: SystemCreateUpdatePage, route: PrivateRoute, roles: ['Admin'] },
  { path: '/user_create', name: 'UserCreate', component: UserCreateUpdatePage, route: PrivateRoute, roles: ['Admin'] },
  { path: '/userModify/:userID', name: 'UserModification', component: UserCreateUpdatePage, route: PrivateRoute, roles: ['Admin'] },
  { path: '/accounts_admin', name: 'AccountsAdmin', component: AccountsAdminPage, route: PrivateRoute, roles: ['Admin'] },
  { path: '/account_create', name: 'AccountCreate', component: AccountCreateUpdatePage, route: PrivateRoute, roles: ['Admin'] },
  { path: '/accountModify/:accountID', name: 'AccountModification', component: AccountCreateUpdatePage, route: PrivateRoute, roles: ['Admin'] },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/systems" />,
    route: PrivateRoute
  },
]

export { routes, PrivateRoute };
