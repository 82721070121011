import i18n from "i18next";
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18n.use(Backend)
.use(initReactI18next) // See https://www.i18next.com/overview/api for options
.init({
  lng: localStorage.getItem('lang') != null ? localStorage.getItem('lang') : 'en',
  keySeparator: false, // we do not use keys
  interpolation: {
    escapeValue: false // React already safes from xss
  }
});

export default i18n;