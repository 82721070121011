import i18n from './i18n';
import {isUserPHT} from "./helpers/authUtils";

let config = {
  production: true,//!(process.env.NODE_ENV && process.env.NODE_ENV === 'development'),
  log: false,
  baseURL: 'https://api.my.aqua4d.com/',
  notificationsCheckInterval: 30,
  baseAccountName: "PHT"
}

if (!config.production) {
  config["log"] = true;
  config["baseURL"] = 'http://127.0.0.1:3000/';
}

i18n.on('languageChanged init', (lang) => {
  if (config.log) console.log(`Translation change detected ${lang}`);
});

config.getLanguages = () => {
  return {
    en: {
      name: i18n.t('english'),
    },
    de: {
      name: i18n.t('german'),
    },
    fr: {
      name: i18n.t('french'),
    },
  };
};

// Languages for the control unit HMI settings (not related to the web interface itself)
config.getHMILanguages = () => {
  return {
    en: {
      name: i18n.t('english'),
    },
    de: {
      name: i18n.t('german'),
    },
    fr: {
      name: i18n.t('french'),
    },
    it: {
      name: i18n.t('italian'),
    },
    es: {
      name: i18n.t('spanish'),
    },
    pt: {
      name: i18n.t('portuguese'),
    },
  };
};

config.getUnits = () => {
  return {
    deg: {
      name: 'C°',
    },
    far: {
      name: 'F°',
    },
  };
};

config.getSyncModes = () => {
  return {
    off: {
      name: i18n.t('off'),
    },
    master: {
      name: i18n.t('master'),
    },
    slave: {
      name: i18n.t('slave'),
    },
    slave_end: {
      name: i18n.t('slave-end'),
    },
  };
};


config.getProfileMenu = () => {
  return [{
    label: i18n.t('settings'),
    icon: 'remixicon-settings-3-line',
    redirectTo: '/settings',
  },
  {
    label: i18n.t('logout'),
    icon: 'remixicon-logout-box-line',
    redirectTo: '/logout',
    hasDivider: true,
  },
  ];
}

config.getAvailableAccountTypes = () => {
  return (isUserPHT() ? ["distributor"] : []).concat(["customer"])
}

export default config;
