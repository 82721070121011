import config from '../config';
import axios from 'axios';
import {getAccessToken, getLoggedInUser} from './authUtils';

const API = axios.create({
    baseURL: config.baseURL,
    headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
    },
});

API.ensureReady = async () => {
    API.defaults.headers.Authorization = `Bearer ${getAccessToken()}`;
}

API.loginUser = (mail, password) => {
    if (config.log) console.log(`Login attempt for ${mail}`);

    return new Promise((resolve, reject) => {
        API.post('/login', { mail, password }).then((success) => {
            if (config.log) console.log(success.data);
            resolve(success.data);
        }).catch((error) => {
            if (config.log) console.log(error);

            reject('Auth failed');
        });
    });
};

API.askPasswordReset = (mail) => {
    if (config.log) console.log(`Ask password reset for ${mail}`);

    return new Promise((resolve, reject) => {
        API.post('/profile/askPasswordReset', { mail }).then((success) => {
            if (config.log) console.log(success.data);
            resolve(success.data);
        }).catch((error) => {
            if (config.log) console.log(error);

            reject('Password reset failed');
        });
    });
};

API.passwordReset = (mail, resetCode, password) => {
    if (config.log) console.log(`Password reset for ${mail}`);

    return new Promise((resolve, reject) => {
        API.put('/profile/passwordReset', { mail, resetCode, password }).then((success) => {
            if (config.log) console.log(success.data);
            resolve(success.data);
        }).catch((error) => {
            if (config.log) console.log(error);

            reject('Password reset failed');
        });
    });
};

API.register = (mail, password, firstname, lastname) => {
    if (config.log) console.log(`Create new user ${mail}`);

    return new Promise((resolve, reject) => {
        API.post('/register', { mail, password, firstname, lastname }).then((success) => {
            if (config.log) console.log(success.data);
            resolve(success.data);
        }).catch((error) => {
            switch(error.response.status) {
                case 409:
                    reject('The email adress is already in use');
                    break;
                default:
                    reject('Unknown error during user creation');
                    break;
            }
        });
    });
};


API.getUsers = () => new Promise((resolve, reject) => {
    API.get('/users').then((users) => {
        resolve(users.data);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject('Get users failed');
    });
});

API.getUser = (userID) => new Promise((resolve, reject) => {
    API.get(`/users/${userID}`).then((user) => {
        resolve(user.data);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject('Get user failed');
    });
});

API.addUser = () => (firstname, lastname, mail, account, admin, lang, timezone, unit) => new Promise((resolve, reject) => {
    API.post(`/users`, {firstname, lastname, mail, account, admin, lang, timezone, unit}).then((success) => {
        resolve(true);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject(error);
    });
});

API.modifyUser = (userID) => (firstname, lastname, mail, account, admin, lang, timezone, unit) => new Promise((resolve, reject) => {
    API.put(`/users/${userID}`, {firstname, lastname, mail, account, admin, lang, timezone, unit}).then((success) => {
        resolve(true);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject(error);
    });
});

API.deleteUser = (userID) => new Promise(async (resolve, reject) => {
    try {
        const system = await API.delete(`/users/${userID}`);
        resolve(system.data);
    }
    catch (error) {
        if (config.log) console.log(error);
        reject(error);
    }
});

API.getFAQ = (lang) => new Promise((resolve, reject) => {
    API.get('/faq').then((questions) => {
        resolve(questions.data);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject('Get faq failed');
    });
});

API.getNotifications = () => new Promise((resolve, reject) => {
    API.get('/notifications').then((notifications) => {
        resolve(notifications.data.map(notification => {
            notification.isRead = notification[getLoggedInUser().account ? "read" : "readPHT"]
            return notification
        }));
    }).catch((error) => {
        if (config.log) console.log(error);
        reject('Get notifications failed');
    });
});

API.markNotificationAsRead = (notification) => new Promise((resolve, reject) => {
    API.post(`/notifications/${notification.id}`).then((success) => {
        resolve(notification);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject('Notification update failed');
    });
});

API.getFiles = () => new Promise((resolve, reject) => {
    API.get('/files').then((files) => {
        resolve(files.data);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject('Get files failed');
    });
});

API.getProfile = () => new Promise((resolve, reject) => {
    API.get('/me').then((profile) => {
        resolve(profile.data);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject('Get profile failed');
    });
});

API.udpateLang = (user, lang) => new Promise((resolve, reject) => {
    return API.updateProfile(user.firstname, user.lastname, user.mail, lang, user.timezone, user.unit, null, null)
});

API.updateProfile = (firstname, lastname, mail, lang, timezone, unit, oldPassword, newPassword) => new Promise((resolve, reject) => {
    API.put('/me', { firstname, lastname, mail, lang, timezone, unit, oldPassword, newPassword }).then((user) => {
        resolve(user.data);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject(error);
    });
});

API.getSystems = () => new Promise((resolve, reject) => {
    API.get('/systems').then((systems) => {
        const systemsWithIcons = systems.data.map((system) => {
            system.icon = 'ok';

            return system;
        });

        resolve(systemsWithIcons);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject('Get systems failed');
    });
});

API.getSystem = (systemId) => new Promise(async (resolve, reject) => {
    try {
        const system = await API.get(`/systems/${systemId}`);
        resolve(system.data);
    }
    catch (error) {
        if (config.log) console.log(error);
        reject('Get system failed');
    }
});

API.addSystem = (serial_num) => (name, owner) => new Promise((resolve, reject) => {
    API.post(`/systems`, {name, serial_num, owner}).then((success) => {
        resolve(true);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject(error);
    });
});

API.modifySystem = (systemId) => (name, owner) => new Promise((resolve, reject) => {
    API.put(`/systems/${systemId}`, {name, owner}).then((success) => {
        resolve(true);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject(error);
    });
});

API.deleteSystem = (systemId) => new Promise(async (resolve, reject) => {
    try {
        const system = await API.delete(`/systems/${systemId}`);
        resolve(system.data);
    }
    catch (error) {
        if (config.log) console.log(error);
        reject(error);
    }
});

API.setSystemName = (systemId, newName) => new Promise((resolve, reject) => {
    API.post(`/systems/${systemId}/name`, {newName}).then((success) => {
        resolve(true);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject('Set system name failed');
    });
});

API.setSystemOptions = (systemId, options) => new Promise((resolve, reject) => {
    API.post(`/systems/${systemId}/options`, options).then((success) => {
        resolve(options);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject('Set system options failed');
    });
});

API.updateSystemFirmware = (systemId) => new Promise((resolve, reject) => {
    API.post(`/systems/${systemId}/firmware_update`).then((success) => {
        resolve();
    }).catch((error) => {
        if (config.log) console.log(error);
        reject('System firmware update failed');
    });
});

API.getAccounts = () => new Promise((resolve, reject) => {
    API.get('/accounts').then((accounts) => {
        resolve(accounts.data);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject('Get accounts failed');
    });
});

API.getDistributors = () => new Promise((resolve, reject) => {
    API.get('/accounts/type/distributor').then((accounts) => {
        resolve(accounts.data);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject('Get accounts failed');
    });
});

API.getAccount = (accountId) => new Promise((resolve, reject) => {
    API.get(`/accounts/${accountId}`).then((account) => {
        resolve(account.data);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject('Get accounts failed');
    });
});

API.addAccount = () => (name, typeName, distributor) => new Promise((resolve, reject) => {
    API.post(`/accounts`, {name, typeName, distributor}).then((success) => {
        resolve(true);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject(error);
    });
});

API.modifyAccount = (accountId) => (name, typeName, distributor) => new Promise((resolve, reject) => {
    API.put(`/accounts/${accountId}`, {name, typeName, distributor}).then((success) => {
        resolve(true);
    }).catch((error) => {
        if (config.log) console.log(error);
        reject(error);
    });
});

API.deleteAccount = (accountId) => new Promise(async (resolve, reject) => {
    try {
        const account = await API.delete(`/accounts/${accountId}`);
        resolve(account.data);
    }
    catch (error) {
        if (config.log) console.log(error);
        reject(error);
    }
});

export default API;
